
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Button, Divider, GridItem, Heading, HStack, Link, SimpleGrid, useTheme, VStack, } from '@chakra-ui/react';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash/isEmpty';
import { GetStaticProps } from 'next';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import ResetPasswordModal from '../components/Modals/ResetPasswordModal';
import HeadModule from '../components/Modules/HeadModule';
import HomeHero from '../components/Modules/HomeHero';
import ServiceList from '../components/Modules/ServiceList';
import SubscriptionsHome from '../components/Modules/SubscriptionsHome';
import { SUPPORTED_MODELS } from '../constants/models';
import { CEDILLE_EXAMPLES_OF_USES, getCedilleRedirectURL } from '../constants/routes';
import { ALL_SKILLS } from '../constants/skills';
import { ExamplePost } from '../dataModels/ExamplePost';
import { useIsMobile } from '../hooks/useIsMobile';
import { fetchExamplePosts } from './api/example-posts';
const Article = dynamic(() => import('../components/Molecules/Article'));
// noinspection JSUnusedGlobalSymbols
const getStaticProps: GetStaticProps = async () => {
    const examplePostsParam = await fetchExamplePosts();
    return {
        props: {
            examplePostsParam,
        },
        revalidate: 900,
    };
};
interface Props {
    examplePostsParam: string;
}
const Index = ({ examplePostsParam }: Props) => {
    const router = useRouter();
    const theme = useTheme();
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    return (<VStack spacing={[20, null, 24]} w='full' align='center' mb={[20, null, 24]}>
      <HeadModule title='meta_title_home' description='meta_description_home'/>
      <HomeHero />
      <VStack spacing={7} w='full' align='start'>
        <Heading color={theme.colors.text.basic} variant='large' id='services-list'>
          {t('skill:gen_skills')}
        </Heading>
        <ServiceList serviceGroups={[...ALL_SKILLS, SUPPORTED_MODELS]}/>
      </VStack>
      <Divider variant='separator2' w={64}/>
      <SubscriptionsHome />
      <VStack spacing={7} w='full' align='start'>
        <HStack w='full' justifyContent='space-between'>
          <Heading as='h2' color={theme.colors.text.basic} variant='large'>
            {t('common:home_examples_of_use_title')}
          </Heading>
          {!isMobile && (<Link href={getCedilleRedirectURL(CEDILLE_EXAMPLES_OF_USES, router.locale)} isExternal>
              <Button variant='textLightPrimary' rightIcon={<FontAwesomeIcon icon={faAngleRight}/>}>
                {t('common:home_more_example_posts_link')}
              </Button>
            </Link>)}
        </HStack>
        <SimpleGrid columns={[1, null, 2, 3]} w='full' gridGap={7}>
          {!isEmpty(examplePostsParam) &&
            JSON.parse(examplePostsParam).map((post: any) => {
                //@ts-ignore
                const temp = Object.assign(new ExamplePost(), post);
                return (<GridItem transition='all 150ms ease-in-out' key={temp.slug} _hover={{ transform: 'scale(1.05)' }}>
                  <Article examplePost={temp}/>
                </GridItem>);
            })}
        </SimpleGrid>
      </VStack>
      <ResetPasswordModal />
    </VStack>);
};
// noinspection JSUnusedGlobalSymbols
export default Index;

    async function __Next_Translate__getStaticProps__18fe3fcf625__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18fe3fcf625__ as getStaticProps }
  